<style lang="css">
   @import './assets/styles/Font.css';
   @import './assets/font/font-awesome/css/font-awesome.css';
   @import './assets/font/font-awesome/css/brands.css';
</style>

<template>
    <div id="app" v-bind:class="{bandeauComm : paramBandeauComm, addeoSmall : addeoSmall, addeoMedium : addeoMedium, addeoLarge : addeoLarge, addeoLargeLow : addeoLargeLow, addeoOnTop : addeoOnTop, addeoNotOnTop : !addeoOnTop, pro : sitePRO, addeoScroll:paramDevScroll, addeoFixed:paramConnexionFixed}">

    <div id="blocVersion" v-if="!isProdApp">
        version : {{version}}
    </div>

    <transition name="fadeSmooth">
        <div id="message" v-bind:class="{messageErreur : !messageOK}"   v-if="message">{{messageTxt}}</div>
    </transition>

    <router-view/>

    <transition name="fadeSmooth2">
        <a v-if="!addeoOnTop && !paramDevScroll" href="#app" class="scrollup" aria-label="Go To Top">&nbsp;</a>
        <a v-if="!addeoOnTop && paramDevScroll" href="javascript:;" @click="scrollToUp" class="scrollup" aria-label="Go To Top">&nbsp;</a>
    </transition>
  </div>
</template>



<script>

    import { addeoPost } from "./js/datas.js"

    export default {
        name: "NomTransitoire",
        data() {
            return {
                sitePRO:false,
                refreshing: false,
                tmp:true,
                addeoHaut:true,
                addeoSmall:true,
                addeoMedium:false,
                addeoLarge:false,
                addeoLargeLow:false,
                addeoOnTop:true,
                myTimeout:null,
                myTimeout2:null,
                rafraichir:false,
                rafraichirDejaDemande:false,
                messageTxt:"",
                messageOK:true,
                message:false,
                pathVue:"/",
                levelDebug:0
            }
        },
        components: {
        },


        beforeCreate() {
        },

        created() {
            this.initialiserResolution();
            this.initialiserScroll();

            document.addEventListener('swUpdated', this.updateAvailable, { once: true }); //test 2023/03

            // 2022/09 BC - passer par le "watch" "rafraichir"
            var thisObj = this;
            //this.myTimeout2 = setTimeout(function(){thisObj.pourSW()}, 3000);
            //this.refreshApp();


        },

        mounted() {
            window.addEventListener("resize", this.myEventHandlerResize);
            window.addEventListener("scroll", this.myEventHandlerScroll);

            this.name = this.lAppli;

        },

        watch:{
            rafraichir(newValue) {
                //if (newValue) { // 2022/09 passer par là
                if (newValue && 1) {
                    var lAttente = 500;
                    if (this.levelDebug) {
                        lAttente = 60000;
                    }
                    this.rafraichir = false;
                    console.log('before reload');
                    setTimeout(function(){
                        console.log('reload en cours');
                        window.location.reload();
                    }, lAttente); // debug ou pas
                }
            },
            $route (to, from){
                    this.pathVue = to.fullPath;
            }
        },

        destroyed() {
            window.removeEventListener("resize", this.myEventHandlerResize);
            window.removeEventListener("scroll", this.myEventHandlerScroll);

        },


        computed: {
            version() {
                return(this.$router.options.version);
            },
            markOrientest() {
                return(this.$router.options.markOrientest);
            },
            paramDevScroll() {
                // il y a du scroll
                return(this.$router.options.paramDevScroll);
            },
            paramConnexionFixed() {
                // connexion fixée et on est sur la home !
                return(!this.addeoSmall && this.$router.options.paramConnexionFixed && (this.pathVue == "/" || this.pathVue.startsWith( "/#")));
            },
            paramPRO() {
                return(this.$router.options.paramPRO);
            },
            lAppli() {
                if (this.markOrientest) {
                    if (!this.paramPRO) {
                        return("MonOrientEst");
                    }
                    else {
                        return("OrientEstPro");
                    }
                }
                else {
                    if (!this.paramPRO) {
                        return("Lorfolio");
                    }
                    else {
                        return("LorfolioPro");
                    }
                }
            },
            isPreProdApp() {
                var leDomaine = window.location.hostname;

                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return(false);
                }
                if (leDomaine.indexOf("grandtest") >= 0) {
                    return(false);
                }
                if (this.isProdApp) {
                    return(false);
                }
                return(true);
            },
            isProdApp() {
                var leDomaine = window.location.hostname;
                 if ((leDomaine === "monorientest.fr") || (leDomaine === "www.monorientest.fr") || (leDomaine === "orientest.pro") || (leDomaine === "www.orientest.pro") || (leDomaine === "ressources.orientest.pro")
                     || (leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                     return(true);
                 }
                return(false);
            },
            paramBandeauComm() {
                return(this.$router.options.paramBandeauComm);
            }
        },

        methods: {
/*
            pourSW() {
                if (this.myTimeout2) {
                    clearTimeout(this.myTimeout2);

                }
                self.addEventListener("activate", (event) => {
                    event.waitUntil(clients.claim());
                });

                console.log('test reload');
                if ((typeof navigator !== "undefined") && (typeof navigator.serviceWorker !== "undefined")) {
                    console.log('navigator.serviceWorker : OK');
                    // Prevent multiple refreshes
                    navigator.serviceWorker.ready.then((registration) => {
                        console.log('navigator.serviceWorker : ready');
                        if (navigator.serviceWorker.ready.ServiceWorkerRegistration && navigator.serviceWorker.ready.ServiceWorkerRegistration.onupdatefound) {
                            console.log('navigator.serviceWorker : update found !');
                        }
                        navigator.serviceWorker.addEventListener('controllerchange', () => {
                            if (this.refreshing) {
                                console.log('refreshing : stop reload');
                                return;
                            }
                            this.refreshing = true
                            // Here the actual reload of the page occurs
                            console.log('before reload');
                            window.location.reload()
                        })
                    });
                }
                else {
                    console.log('no reload');
                }
            },
*/
            finMessage() {
                if (this.myTimeout) {
                    clearTimeout(this.myTimeout);
                }
                this.message = false;
            },
            myEventHandlerResize(e) {
                this.initialiserResolution();
            },
            myEventHandlerScroll(e) {
                this.initialiserScroll();
            },
            initialiserScroll() {
                if (window.scrollY) {
                    this.addeoOnTop = false;
                }
                else {
                    this.addeoOnTop = true;
                }
            },
            initialiserResolution() {
                this.initialiserScroll();
                if (document.body) {
                    var hauteurEcran = window.innerHeight;
                    var largeurEcran = document.body.clientWidth;
                    if (largeurEcran >= 992) {
                        this.addeoSmall=false;
                        this.addeoMedium=false;
                        this.addeoLarge=true;
                        if (largeurEcran >= 1182) {
                            this.addeoXLarge=true;
                        }
                        else {
                            this.addeoXLarge=false;
                        }
                    }
                    else {
                        if (largeurEcran >= 641) {
                            this.addeoLargeLow=false;
                            this.addeoSmall=false;
                            this.addeoLarge=false;
                            this.addeoMedium=true;
                        }
                        else {
                            this.addeoLargeLow=false;
                            this.addeoLarge=false;
                            this.addeoMedium=false;
                            this.addeoSmall=true;
                        }
                    }
                    let hauteurLargeLow = 775;
                    if (this.markOrientest) {
                        hauteurLargeLow = 900;
                    }
                    if (hauteurEcran < hauteurLargeLow) {
                        this.addeoHaut=false;
                        if (this.addeoLarge) {
                            //if (!this.paramPRO && !this.markOrientest) {
                            if (!this.markOrientest/**/ || !this.paramPRO/**/) {
                                this.addeoLargeLow=true;
                                this.addeoLarge=false;
                                this.addeoMedium=true;
                            }
                        }
                    }
                    else {
                        this.addeoHaut=true;
                        this.addeoLargeLow=false;
                    }
                }
            },
            updateAvailable(event) {
                console.log('swUpdatedDetected !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! : ', event.detail);
                this.registration = event.detail;
                this.refreshApp();
            },
            /*
            // Called when the user accepts the update
            refreshApp() {
                console.log('refreshApp');
                // Make sure we only send a 'skip waiting' message if the SW is waiting
                if (!this.registration || !this.registration.waiting) return

                var thisObj = this;

                navigator.serviceWorker.getRegistrations().then(function(registrations) {
                    for(let registration of registrations) {
                        registration.unregister(); // pris en compte uniquement après reload !!!!
                        console.log('serviceWorker unregister : ');
                    }
                    // send message to SW to skip the waiting and activate the new SW
                    thisObj.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
                })

                // send message to SW to skip the waiting and activate the new SW
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            },
            */
            // à partir de STERES
            refreshApp() {
                console.log('refreshApp : entrée');

                var thisObj = this;

if ((typeof navigator !== "undefined") && (typeof navigator.serviceWorker !== "undefined")) {
    console.log('refreshApp -> navigator.serviceWorker : OK');
    // Prevent multiple refreshes
    navigator.serviceWorker.ready.then((registrationOK) => {


        console.log('refreshApp : version', thisObj.version);
        console.log('refreshApp : registrationOK', registrationOK);


                /**/
                navigator.serviceWorker.getRegistrations().then(function(registrations) {
//if ( thisObj.version.localeCompare(registrationOK.laVersion)) {
                    console.log('serviceWorker demandes unregister : ', registrations);
                        for(let registration of registrations) {
                            console.log('refreshApp unregister : ', registration);
                            registration.unregister(); // pris en compte uniquement après reload !!!!
                        }
//}

                    // send message to SW to skip the waiting and activate the new SW
                    if (thisObj.registration && thisObj.registration.waiting) {
                        thisObj.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
                    }


if (thisObj.levelDebug == 2) {
                        console.log('refreshApp abandon pour debug');
                        return;
}

                    console.log('refreshApp : rafraichir');

                    // BC 2021/04 - reload
                    if (!thisObj.rafraichirDejaDemande) {
                        thisObj.rafraichir = true;
                    }
                    thisObj.rafraichirDejaDemande = true;
                    return;
                })
    })
}
            },
            urlDomaineApp() {
                var leDomaine = window.location.hostname;
                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return("https://grandtest.addeo.ovh"); // URL de test
                }

                if (leDomaine.indexOf("grandtest") >= 0) {
                    return("https://grandtest.addeo.ovh"); // URL de test
                }

                if ( (leDomaine !== "mon.orientest.fr") && (leDomaine !== "www.mon.orientest.fr") && (leDomaine !== "monorientest.fr") && (leDomaine !== "www.monorientest.fr") && (leDomaine !== "orientest.pro") && (leDomaine !== "www.orientest.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                    return("https://preprod.monorientest.fr"); // URL de preprod
                }
                else {
                     if ((leDomaine === "monorientest.fr") || (leDomaine === "www.monorientest.fr") || (leDomaine === "orientest.pro") || (leDomaine === "www.orientest.pro")) {
                        return('https://monorientest.fr') // URL de prod
                     }
                }
            },
            scrollToUp() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }

    
</script>

<style lang="scss">



html {
    height: 100%;
/*    scroll-behavior: smooth;*/


    body {
        min-height: 100%;
        margin: 0;
        padding: 0;
        position:relative;
        text-align: center;
        min-width: 320px;

        #app {

            *, :after, :before {
                background-repeat: no-repeat;
                box-sizing: content-box;
            }

            &.addeoSmall {
                .containerAffichage {
                    width:100%;
                    /*margin:0 auto;*/
                    margin:4em auto;
                }
            }
            &.addeoMedium {
                .containerAffichage {
                    width:100%;
                    max-width: 980px;
                    margin:4em auto;
                }
            }


            #message {
                position: fixed;
                bottom:2em;
                width: 90%;
                padding:1em 5%;
                /*
                position: absolute;
                bottom:0vh;*/
                /*transform: translateY(-50vh);*/
                left:0;
                text-align: center;
                background-color: #4B3C8F;
                color:white;
                z-index: 500;
                height: 3em;
                line-height: 1.3em;
                box-shadow: 0 1em 1em #777;
            }

.fadeSmooth-enter-active {
    transition: all .1s ease;
}
.fadeSmooth-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fadeSmooth-enter, .fadeSmooth-leave-to {
    opacity: 0;
}
/*
.fadeSmooth2-enter-active {
    transition: all .5s ease;
}
.fadeSmooth2-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
*/
.fadeSmooth2-enter-active, .fadeSmooth2-leave-active {
  transition: opacity 1s;
}
.fadeSmooth2-enter, .fadeSmooth2-leave-to {
    opacity: 0;
}

            position:relative;
            width:100%;
            max-width: 1920px;
            min-width: 320px;
            margin:0 auto;
            background-color: white;

            .mevMoyen {
                font-weight: 600;
            }
            .insecable {
                white-space: nowrap;
            }

            :focus {
                outline: transparent solid 1px  !important;
            }

            a {
                text-decoration: none;
                color: #356A73; /* anciennement #33AD98; pb accessibilité */
                &:hover, &:focus {
                    color : #4B3C8F;
                    }
            }
            div {
                position : relative;
            }

            .containerAffichage {
                width:98%;
                max-width: 1260px;
                margin:4% auto;
            }
            .containerIFrame {
                @media screen and (max-width: 321px) {
                    max-width:300px;
                    margin:0 !important;
                }
            }

            h2.sousTitre {
                color:#356A72;
                text-transform: uppercase;
                font-size:2.6em;
                vertical-align: middle;
                background: no-repeat center bottom transparent url("~@/assets/img/barre.svg");
                padding-bottom: 0.5em;
                margin-bottom: 1em;

                img {
                    max-width:212px;
                    &.marqueOrientest {/* MARK ORIENTEST */
                        max-height: 0.7em;
                        max-width:none;
                    }
                }
                .commeSousTitre {
                    color:#4b3c8f;
                }

            }


            font-family: Montserrat, Roboto, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-align: center;
            color: #2c3e50;

            .clear {
                clear: both;
            }
        }
    }
}


html {
    body {
        #app.pro {
            .markOrientest {
                h2.sousTitre {
                    font-size:2.3em;
                }
            }
        }
    }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: pink;
    }
  }
}

    /************************************************ PRO *********************************************/

html {
    body {

#app {

    form {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px #EBEBEB inset !important;
            -webkit-text-fill-color: 2C3E50 !important;
        }
        ::-ms-reveal {
            display: none;
        }
    }


    input:-internal-autofill-selected {
        appearance: menulist-button;
        /*background-color: rgba(0, 0, 0, 0.2) !important;*/
        background-color: #EBEBEB;
        color: #2C3E50;
        /*background-image: none !important;*/
        /*color: -internal-light-dark(black, white) !important;*/
    }

    .dansFooter {
        form {
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active  {
                -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
            }
        }
        input:-internal-autofill-selected {
            background-color: #FFFFFF;
        }
    }


    input {
        filter: none !important;
    }
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }

    #blocVersion {
        color:#777;
        background: transparent;
        position: absolute;
        z-index: 2;
        font-size:70%;
        right:10px;
        top:5px;
    }

    .scrollup {
        display:inline;
        color: white;
/*        background-color: #004164;
        border: 1px solid #004164;*/
        background-color: #356a73;
        border: 1px solid #fff;
        margin-top: 1em;
        margin-bottom: 1em;
        font-weight: bold;

        bottom: 0px;
        right: 40px;
        width: 40px;
        height: 40px;
        position: fixed;
        z-index: 999;
        text-decoration: none;
        &::before {
            /*
            content: " \f357";
            font-weight: 700;
            */
            content: " \f0d8";
            font-weight: 500;
            font-family: "FontAwesome";
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 3px;
            width: 40px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 25px;
            color: #fff;
            box-sizing: border-box;
        }
        &:hover, &:focus {
            background-color: #fff;
            border: 1px solid #356a73;
            &::before {
                color: #356a73;
            }
        }
    }
/*
    &.addeoOnTop  {
        .scrollup {
            display:none;
        }
    }
    &.addeoNotOnTop  {
        .scrollup {
            display:inline;
        }
    }
*/
}

        #app:not(.pro) {

            .markOrientest {
                h2.sousTitre {
                    text-transform: none;
                    }
            }

            &.addeoSmall {

                div.markOrientest > .containerAffichage {
                    margin-top:-1em !important;
                }
                .markOrientest {
                    h2.sousTitre {
                        max-width: 90%;
                        font-size: 1.4em;
                        margin:0 auto 1.4em;
                        text-transform: none;
                        }
                }
            }
        }

        #app.pro {
            h2.sousTitre {
                text-transform: unset;
                background: no-repeat center bottom transparent url("~@/assets/img/pro/barre.svg");
            }
            .containerAffichage #contenu2 {
                /*
                text-transform: unset;
                background: no-repeat center bottom transparent url("~@/assets/img/pro/barre-grise.svg");
                */
            }
            #grandEst .containerAffichage {
                margin: 0 auto;
            }
            &.addeoSmall {
                h2.sousTitre {
                    max-width: 95%;
                    font-size: 2.4em;
                    }
                div.markOrientest > .containerAffichage {
                    margin-top:-1em !important;
                }
                .markOrientest {
                    h2.sousTitre {
                        max-width: 90%;
                        font-size: 1.4em;
                        margin:0 auto;
                        }
                }
            }
            > div {
                background-color: #F8F8F8;

                &.home {
                    background-color: white;
                }
            }
        }
    }
}

</style>
