<template>
  <div id="contact" class="noScrollup">

    <div id="container-contact" class="containerAffichage containerIFrame">

        <div class="title">
            <h3 v-if="!sitePRO">Une question ? Un message ?</h3>
            <h3 v-if="sitePRO && (lesParams != 'AgendaPro')">Pros : Une question ? Un message ?</h3>
            <h3 v-if="sitePRO && markOrientest && (lesParams == 'AgendaPro')">Demande de rencontre Orient'Est PRO</h3>
            <h3 v-if="sitePRO && !markOrientest && (lesParams == 'AgendaPro')">Demande de rencontre Lorfolio PRO</h3>
        </div>

        <div class="consigne">Tous les champs suivis d'une * sont obligatoires</div>

        <div class="edition">

            <form id="ProprieteEditionForm">


                        <v-text-field
                            label="Nom *"
                            v-model="form.nom"
                            required
                            :error-messages="error.nom"
                            ref="nom"
                            title="Saisissez votre nom"
                        ></v-text-field>
                        <v-text-field
                            label="Adresse courriel *"
                            v-model="form.adresseMail"
                            required
                            :error-messages="error.adresseMail"
                            ref="adresseMail"
                            title="Saisissez votre adresse de courriel"
                        ></v-text-field>
                        <v-text-field
                            label="Objet *"
                            v-model="form.sujet"
                            required
                            :error-messages="error.sujet"
                            ref="sujet"
                            title="Indiquez l'objet de votre demande"
                        ></v-text-field>
                        <v-textarea
                            label="Votre message *"
                            required
                            :counter="1265"
                            v-model="form.message"
                            ref="message"
                            :error-messages="error.message"
                            title="Précisez votre demande en détaillant au maximum votre situation"
                        ></v-textarea>

                      <v-checkbox
                                  v-if="0"
                        v-model="form.utilisateurCopie"
                        on-icon="fa fa-check-square"
                        off-icon="fa fa-square"
                        label="Tester apiagenda.lorfolio.fr"
                        @change="testAgenda"
                        ></v-checkbox>

            <span v-if="this.ready"class="consigneAntiSpam">Validation anti-spam * : <span v-if="this.ready" class="consigne">{{laVerif}}</span></span>
            <v-text-field v-if="this.ready"
                            :label="laSousVerif"
                            v-model="resultat"
                            required
                            :error-messages="error.resultat"
                            ref="resultat"
                        ></v-text-field>
                </form>
        </div>

        <!-- les boutons peuvent être mis dans un composant pour être générique -->
        <div class="boutonsValidation">
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Envoyer</v-btn>
        </div>

    </div>

  </div>
</template>


<script>

import { addeoPost } from "../js/datas.js"

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, minValue, maxValue } from 'vuelidate/lib/validators'



export default {
    name: 'Contact',
    data() {
        return {
            titi:"tata",
            form:  {
                origine:"",
                nom:"",
                adresseMail:"",
                sujet:"",
                message:"",
                utilisateurCopie:true,
            },
            resultat:"",
            old: {

            },
            error: {
                nom: '',
                sujet: '',
                adresseMail: '',
                message:"",
                resultat: ''
            },
            ready:false,
            antiSpam1:89,
            antiSpam2:72,
            myTimeout:null,
        }
    },
    components: {
    },
    props: {
        sitePro: {
            type: Boolean
        },
    },
    created() {
        if (this.markOrientest) {
            this.form.origine = "monorientest.fr";
            if (this.sitePro) {
                this.$parent.sitePRO = true;
                this.form.sujet = "Contact OrientEst PRO";
                this.form.origine = "orientest.pro";
            }

            if (this.sitePRO && this.lesParams == 'AgendaPro') {
                this.form.sujet = "Demande de rencontre Orient’Est PRO";
            }
        }
        else {
            if (this.sitePro) {
                this.$parent.sitePRO = true;
                this.form.sujet = "Contact Lorfolio PRO";
            }

            if (this.sitePRO && this.lesParams == 'AgendaPro') {
                this.form.sujet = "Demande de rencontre Lorfolio PRO";
            }
        }


        this.myTimeout = setTimeout(this.preparerAntispam, 1500);
    },

    validations() {
        return {
            form: {
                nom: { required, maxLength: maxLength(665) },
                sujet: { required, maxLength: maxLength(665) },
                adresseMail: { required, email },
                message: { required, maxLength: maxLength(1265) },
            }
        }
    },
    computed: {
        sitePRO() {
            return(this.$parent.sitePRO);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        lesParams() {
            return(this.$route.params.pere);
        },
        laSousVerif() {
            return(this.antiSpam1+" + "+ this.antiSpam2+" =");
        },
        laVerif() {
            return("Donnez le résultat de la somme "+this.antiSpam1+" + "+ this.antiSpam2);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    },
    methods: {
        getRandomInt(max) {
          return Math.floor(Math.random() * Math.floor(max))+1;
        },
        preparerAntispam() {
                if (this.myTimeout) {
                    clearTimeout(this.myTimeout);
                }
            this.antiSpam1= this.getRandomInt(50);
            this.antiSpam2= this.getRandomInt(30);
            this.ready = true;
        },

        testAgenda() {
            return addeoPost.posterDemandeAgenda("[{}]")
                 .then(response => {
                    var titit = response;
                    return(titit);
                })
            },

        // enregistrement du formulaire
        async saveForm() {

            var thisObj = this;
            // on reinit à vide les messages d'erreur
            thisObj.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (this.resultatIsFalse() || thisObj.$v.$invalid) {
                // maj erreurs
                thisObj.sujetErrors();
                thisObj.adresseMailErrors();
                thisObj.messageErrors();
                thisObj.nomErrors(); // dans l'ordre inverse pour le focus
                return false
            }


            // on sauvegarde (distant et local)
            thisObj.old = JSON.parse(JSON.stringify(thisObj.form)) // l'éta "en cours" du formulaire devient l'ancien (pour la restauration)
            var entityToSave = JSON.parse(JSON.stringify(thisObj.form))
            console.log("saveForm OK")

            return await addeoPost.posterContact(entityToSave) // on sauvegarde notre propriété (en DB locale et SI POSSIBLE en ligne)
             .then(response => {
                var message = "";
                var ok = false;
                //var ok = true;
                if (this.markOrientest) {
                    if (!response || !response.ok) {
                        message = "Votre demande de contact n'a pu être transmise : veuillez envoyer un mail à contact@orientest.fr";
                    }
                    else {
                        message = "Votre demande de contact a bien été transmise. L'équipe Orient'Est vous remercie et reviendra vers vous dans les meilleurs délais.";
                        ok = true;
                    }
                }
                else {
                    if (!response || !response.ok) {
                        message = "Votre demande de contact n'a pu être transmise : veuillez envoyer un mail à contact@lorfolio.fr";
                    }
                    else {
                        message = "Votre demande de contact a bien été transmise. L'équipe Lorfolio vous remercie et reviendra vers vous dans les meilleurs délais.";
                        ok = true;
                    }
                }

                // on prépare le message passé à la prochaine page
//                message = "La saisie de la propriété "+this.form.identifiant+ " a bien été enregistrée.";

                // on va au bon endroit avec les bonnes valeurs
                this.terminerEdition(message, ok);
            })

        },


        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message, ok) {
            if (message) {
                this.$parent.message=true;
                this.$parent.messageTxt=message;
                this.$parent.messageOK=ok;
                this.$parent.myTimeout = setTimeout(this.$parent.finMessage, 3000);
            }

            if (ok) {
                // bidouille : faire autrement ?
                if ((typeof this.lesParams !== "undefined") && (this.lesParams !== "footer") && (this.lesParams !== "footerPro")) {
                    this.$router.push({ name: this.lesParams }); // pour retour à la page appelante
                }
                else {
                    if (this.sitePRO) {
                        this.$router.push({ name: "Pro" });
                    }
                    else {
                        this.$router.push({ name: "Home" });
                    }
                }
            }
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.nom = ''
            this.error.adresseMail = ''
            this.error.sujet = ''
            this.error.resultat = ''
        },

        nomErrors () {
            const errors = [];
            if (this.$v.form.nom.$invalid) {
                this.error.nom = "Vous devez obligatoirement saisir votre nom";
                if (!this.$v.form.nom.maxLength) {
                    this.error.nom  = "La taille maximale pour cette saisie est de 600 caractères"
                }
                this.$refs.nom.focus();
                return false;
            }
            else {
                this.error.nom = "";
            }
            return true;
        },
        messageErrors () {
            const errors = [];
            if (this.$v.form.message.$invalid) {
                this.error.message = "Vous devez obligatoirement saisir un message";
                if (!this.$v.form.message.maxLength) {
                    this.error.message  = "La taille maximale du message est de 1265 caractères"
                }
                this.$refs.message.focus();
                return false;
            }
            else {
                this.error.message = "";
            }
            return true;
        },
        adresseMailErrors () {
            const errors = [];
            if (!this.$v.form.adresseMail.email || this.$v.form.adresseMail.$invalid) {
                this.error.adresseMail = "Vous devez saisir une adresse courriel valide";
                this.$refs.adresseMail.focus();
                return false;
            }
            else {
                this.error.adresseMail = "";
            }
            return true;
        },
        sujetErrors () {
            const errors = [];
            if (this.$v.form.sujet.$invalid) {
                this.error.sujet = "Vous devez indiquer la raison pour laquelle vous nous contactez";
                if (!this.$v.form.sujet.maxLength) {
                    this.error.sujet  = "La taille maximale pour cette saisie est de 600 caractères"
                }
                this.$refs.sujet.focus();
                return false;
            }
            else {
                this.error.sujet = "";
            }
            return true;
        },
        resultatIsFalse () {
            if (this.resultat != (this.antiSpam1 + this.antiSpam2)) {
                this.error.resultat = "Vous devez indiquer le résultat du calcul";
                this.$refs.resultat.focus();
                return true;
            }
            else {
                this.error.resultat = "";
            }
            return false;
        },
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .addeoSmall {
        #contact {
            #container-contact {
                div.edition {
                    width: 95%;
                    max-width: 1000px;
                }
                .boutonsValidation {
                    button {
                        width:75%;
                    }
                }
            }
        }
    }

    #contact {

        $couleur-fond: #eee;
        $couleur-texte: #223938;

        #container-contact {
            text-align: center;

            div.consigne {
                margin:1em 0;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }
            .consigneCalcul {
                margin:0.3em 0;
                color:#356A73;
            }

            div.edition {
                width: 100%;
                max-width: 405px;
                margin: 1em auto;
                text-align: left;
            }

            .v-input {

                .v-input__slot, .v-text-field__slot {
                    background-color: #F8F8FC;
                    border-radius: 5px 5px 0 0;

                    input, textarea {
                        margin:0.4em 2em;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    label {
                        left:1em !important;
                        right:auto !important;
                    }
                }
                .v-messages__message {
                    color:darkorange;
                }

            }
        }
    }



html {
    body {
        #app.addeoSmall, #app.addeoMedium, #app.addeoLarge  {
            > div {
                background-color: white !important;
            }
            #message.messageErreur {
                background-color: firebrick !important;
            }
            .scrollup {
                display:none;
            }
            .containerAffichage {
                margin: 0 auto;
                padding: 2% 0;
            }
        }
    }
}



/**/
    @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
    /**/


    /* reprise matérial design : 2022-02 */



    #contact {

        #container-contact {
            font-family: Roboto, Arial, sans-serif;
            $couleur-fond: #eee;
            $couleur-texte: #223938;

            text-align: center;
            width: 100%;

            div.consigne {
                margin:1em 0;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }
            .consigneAntiSpam {
                padding-bottom: 0.4em;
                display: inline-block;
                .consigne {
                    color:#356A73;
                }
            }

            div.edition {
                width: 100%;
                max-width: 600px;
                /*margin: 1.0em auto 0.8em;*/
                margin: 10px auto 10px;
                text-align: left;
            }

            .v-input {
                display:inline-block;
                margin: 0;
                padding:0;
                /*
                width: 33%;
                max-width: 280px;
                */
                width: 100%;

                .v-input__slot, .v-text-field__slot {

                    background-color:#EBEBEB;
                    color:#252525;
                    background-color:#EBEBEB;
                    color: #2C3E50;

                    border-radius: 4px 4px 0 0;
                    width:100%;
                    margin-bottom:0;
                    /*margin-bottom: 1.4em;*/

                    input, textarea {
                        font-size:100%;
                        margin:0 5%;
                        padding:1.0em 0 0.1em;
                        width:90%;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    textarea {
                        margin-right: 0;
                    }
                    label {
                        left:1em !important;
                        top:0.6em !important;
                        right:auto !important;
                        color:#356A73;
                        width: 100%;
                    }
                    .v-label--active {
                        transform: translateY(-8px) scale(.6);
                        left: -15% !important;
                        right:unset !important;
                    }
                }


                .v-text-field__details {
                    height: 9px;
                    left: 0;
                    margin:0.1em 0 0.2em;
                    /*
                    position: absolute;
                    top: -0.8em;
                    */
                    .v-messages {
                        min-height: 0px;
                        display:inline;
                        .v-messages__message {
                            color:#b00020;
                            font-size: 85%;
                            vertical-align: top;
                        }
                    }
                }

                &.error--text {
                    label {
                        color: #b00020;
                    }
                    .v-input__slot:before {
                        border-color: #b00020;
                    }
                }
            }
            .v-input:not(.v-input--is-label-active) {
                .v-input__slot {
                    .v-text-field__slot {
                        input {
                            /*
                            color:transparent;
                            */
                        }
                    }
                }
            }
            .boutonsValidation {
                font-family: Montserrat, Roboto, Arial, sans-serif;
                button {
                    font-size: 70%;
                    letter-spacing: 0.03em;
                    padding: 2px 9px;
                    margin-top:0;
                    height:30px;
                    text-transform: none;

                    color:white;
                    background-color: #356A73;
                    border-radius: 0;
                    box-shadow: none;
                    border: 1px solid #356A73;
                    .v-btn__content {
                        background-color: transparent;
                    }
                    &:hover, &:focus {
                        background-color: #fff;
                        color: #356a73;
                        &:before  {
                            opacity: 1;
                            background-color: transparent;
                            transition: none;
                        }
                    }
                }
            }

        }
/*
            #container-contact {
                .edition {

                    .v-input {
                        .v-text-field__slot {
                            background-color:#EBEBEB;
                            color: #2C3E50;
                        }

                    }
                }

            }
*/

    }


</style>
