<template>
  <div id="contactAgenda" class="noScrollup">

    <transition name="fadeSmooth">
        <div id="message" v-bind:class="{messageErreur : !messageOK}"   v-if="message">{{messageTxt}}</div>
    </transition>

    <div id="container-contact" class="containerAffichage containerIFrame">

        <div class="containerAttention">
            <div class="containerInfoBis">
                <div class="infoBis">Seuls les porteurs, organisateurs ou structures de l’orientation-formation-emploi-insertion peuvent proposer des événements futurs pour l’agenda de l'orientation. Les administrateurs de l'agenda se réservent le droit de refuser la proposition si celle-ci ne correspond pas à la charte éditoriale.</div>
            </div>

            <div class="containerRedacteur" v-if="inscriptionRedacteur">
                <div class="inscriptionRedacteur">
                    <a target="_top" v-bind:href="inscriptionRedacteurUrl"
                           class="centrer">
                    Inscrivez-vous à la formation "Devenir Rédacteur de l'agenda de l'orientation"
                    </a>
                </div>
    <!--
                    Inscrivez-vous à la formation<br/>"Devenir Rédacteur de l'agenda de l'orientation"</div>
                <div class="boutonsValidation boutonInscription centrer">
                    <a target="_top" v-bind:href="inscriptionRedacteurUrl"
                           class="a-btn prioritaire centrer">
                            INSCRIPTION
                    </a>
                </div>
    -->
            </div>
        </div>

        <div class="title">
            <h3 v-if="!sitePRO">Proposer un événement en Grand Est</h3>
        </div>

        <div class="consigne">Tous les champs suivis d'un * sont obligatoires</div>

        <div class="edition">

            <form id="contactOEForm">

                <v-text-field
                    label="Nom *"
                    v-model="form.nom"
                    required
                    :error-messages="error.nom"
                    ref="nom"
                    title="Saisissez votre nom"
                ></v-text-field>
                <v-text-field
                    label="Prénom"
                    v-model="form.prenom"
                    :error-messages="error.prenom"
                    ref="prenom"
                    title="Saisissez votre prénom"
                ></v-text-field>
                <v-text-field
                    label="Adresse courriel *"
                    v-model="form.adresseMail"
                    required
                    :error-messages="error.adresseMail"
                    ref="adresseMail"
                    title="Saisissez votre adresse de courriel"
                ></v-text-field>
<!--
                    <v-select
                        dense
                        attach
                        required
                        ref="statut"
                        v-model="form.statut"
                        item-text="libelle"
                        item-value="libelle"
                        :items="lesStatuts"
                        label="Situation *"
                        :error-messages="error.statut"
                        :return-object="false"
                        @change="onChangeStatut()"
                        @focus="($event) => {$event.target.click()}"
                        @blur="closeSelect()"
                    ></v-select>
                    <div id="autreStatut"  v-if="autre">
                        <v-text-field
                            label="Précisez *"
                            v-model="form.autreStatut"
                            ref="autreStatut"
                            title="Précisez votre statut"
                            :error-messages="error.autreStatut"
                        ></v-text-field>
                    </div>
-->
                    <v-text-field
                        label="Objet *"
                        v-model="form.sujet"
                        required
                        :error-messages="error.sujet"
                        ref="sujet"
                        title="Proposition pour l'agenda"
                    ></v-text-field>
                    <span class="consigne consigneContextuelle">Indiquez le nom de votre structure et sa localisation</span>
                    <v-textarea
                        label="Votre structure *"
                        v-model="form.structure"
                        required
                        :counter="600"
                        :error-messages="error.structure"
                        ref="structure"
                        rows="2"
                        title="Décrivez l'événement que vous proposez"
                    ></v-textarea>
                    <span class="consigne consigneContextuelle">Indiquez le titre, la description, le lien web, la date, les horaires, l'adresse précise et le public cible. Si vous disposez d'un visuel (affiche, photo, logo…), merci de l'envoyer à contact@orientest.fr</span>
                    <v-textarea
                        label="Votre proposition d'événement *"
                        v-model="form.message"
                        required
                        :counter="1265"
                        :error-messages="error.message"
                        ref="message"
                        title="Décrivez l'événement que vous proposez"
                    ></v-textarea>

                    <v-checkbox
                      v-if="0"
                        v-model="form.utilisateurCopie"
                        on-icon="fa fa-check-square"
                        off-icon="fa fa-square"
                        label="Tester apiagenda.lorfolio.fr"
                        @change="testAgenda"
                    ></v-checkbox>

                    <span v-if="this.ready"class="consigneAntiSpam">Validation anti-spam *
                        <span v-if="this.ready" class="consigne">{{laVerif}}</span>
                        <v-text-field v-if="this.ready"
                            :label="laSousVerif"
                            v-model="resultat"
                            required
                            :error-messages="error.resultat"
                            ref="resultat"
                        ></v-text-field>
                    </span>
            </form>
        </div>

        <!-- les boutons peuvent être mis dans un composant pour être générique -->
        <div class="boutonsValidation">
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Envoyer</v-btn>
        </div>

    </div>

  </div>
</template>


<script>

import { addeoPost } from "../js/datas.js"
import { addeoGet } from "@/js/datas.js";

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, minValue, maxValue } from 'vuelidate/lib/validators'



export default {
    name: 'Contact',
    data() {
        return {
            //toto:false,
                messageTxt:"",
                messageOK:true,
                message:false,
                myMessageTimeout:null,
                retourSessions:null,
                refFormationProfessionnalisationRedacteurAgenda:"REG-101",
                refBlocFormationProfessionnalisationRedacteurAgenda:"REG",
            form:  {
                origine:"orientest.fr",
                nom:"",
                prenom:"",
                adresseMail:"",
                statut:"",
                autreStatut:"",
                sujet:"",
                structure:"",
                message:"",
                utilisateurCopie:true,
            },
            resultat:"",
            old: {

            },
            error: {
                nom: '',
                prenom: '',
                sujet: '',
                adresseMail: '',
                statut:'',
                autreStatut:'',
                structure:'',
                message:'',
                resultat: ''
            },
            ready:false,
            antiSpam1:89,
            antiSpam2:72,
            myTimeout:null,
            myTimeoutStatutFocus:null
        }
    },
    components: {
    },
    props: {
        sitePro: {
            type: Boolean
        },
    },
    created() {
        this.myTimeout = setTimeout(this.preparerAntispam, 1500);
        this.form.sujet = "Proposition pour l'agenda";
    },
    mounted() {
        //this.myTimeout = setTimeout(this.toto=true, 2500);
        return addeoGet.nbSessionsFormationReferencee(this.refFormationProfessionnalisationRedacteurAgenda, this.refBlocFormationProfessionnalisationRedacteurAgenda, this.$store)
        .then(response => {
            this.retourSessions = response;
        })
    },

    validations() {
        return {
            form: {
                nom: { required, maxLength: maxLength(665) },
                prenom: { maxLength: maxLength(665) },
                sujet: { required, maxLength: maxLength(665) },
                adresseMail: { required, email },
                structure: { required, maxLength: maxLength(600) },
                message: { required, maxLength: maxLength(1265) },
            }
        }
    },
    computed: {
        inscriptionRedacteur() {
            if (this.retourSessions) {
                return(this.retourSessions.nbSessions);
            }
            return(0);
        },
        inscriptionRedacteurUrl() {
            if (this.retourSessions) {
                return(this.urlProfessionalisation+"?link="+this.refFormationProfessionnalisationRedacteurAgenda);
                /* on abandonne la différenciation des cas
                if (this.retourSessions.nbSessions == 1) {
                    return(this.retourSessions.url);
                }
                return(this.urlProfessionalisation+"?link="+this.refFormationProfessionnalisationRedacteurAgenda);
                */
            }
            return("");
        },
        autre() {
            if (this.form.statut == "Autre") {
                return(true);
            }
            return(false);
        },
        lesStatuts() {
            var  tabStatuts = [
                {libelle:"Professionnel de l’orientation", code:"PF"},
                {libelle:"Professionnel de la formation", code:"PO"},
                {libelle:"Autre", code:"A"}];
            return(tabStatuts);
        },
        sitePRO() {
            return(this.$parent.sitePRO);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        lesParams() {
            return(this.$route.params.pere);
        },
        laSousVerif() {
            return("Votre réponse *");
            //return(this.antiSpam1+" + "+ this.antiSpam2+" =");
        },
        laVerif() {
            return("Donnez le résultat de la somme "+this.antiSpam1+" + "+ this.antiSpam2);
        },
        urlProfessionalisation() {
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                return("https://portailorientestpro.grandtest.addeo.ovh/professionnalisation"); // URL de test
            }

            if (leDomaine.indexOf("grandtest") >= 0) {
                return("https://portailorientestpro.grandtest.addeo.ovh/professionnalisation"); // URL de test
            }

            if (leDomaine.indexOf("preprod") >= 0) {
                return("https://preprod.portail.orientest.pro/professionnalisation"); // URL de preprod
            }
            else {
                return('https://www.orientest.pro/professionnalisation') // URL de prod
            }
        }
    },
    methods: {
        reinitForm()  {
                this.form.nom="";
                this.form.prenom="";
                this.form.adresseMail="";
                this.form.statut="";
                this.form.autreStatut="";
                this.form.sujet="";
                this.form.structure="";
                this.form.message="";
        },
/*
        openSelect() {
        },
*/
        closeSelect() {
            const closeEl = this.$refs.statut;
            if (closeEl) {
               closeEl.blur();
            }
        },
        onChangeStatut() {
            if (this.autre) {
                this.myTimeoutStatutFocus = setTimeout(this.preparerStatutFocus, 500);
            }
        },
        preparerStatutFocus() {
                if (this.myTimeoutStatutFocus) {
                    clearTimeout(this.myTimeoutStatutFocus);
                }
            this.$refs.autreStatut.focus()
        },
        finMessage() {
            if (this.myMessageTimeout) {
                clearTimeout(this.myMessageTimeout);
            }
            this.message = false;
        },
        getRandomInt(max) {
          return Math.floor(Math.random() * Math.floor(max))+1;
        },
        preparerAntispam() {
                if (this.myTimeout) {
                    clearTimeout(this.myTimeout);
                }
            this.antiSpam1= this.getRandomInt(50);
            this.antiSpam2= this.getRandomInt(30);
            this.ready = true;
        },

        testAgenda() {
            return addeoPost.posterDemandeAgenda("[{}]")
                 .then(response => {
                    var titit = response;
                    return(titit);
                })
            },

        // enregistrement du formulaire
        async saveForm() {

            var thisObj = this;
            // on reinit à vide les messages d'erreur
            thisObj.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            const retourStatut = this.statutIsFalse();
            const retourResultat = this.resultatIsFalse();
            if (retourStatut || retourResultat || thisObj.$v.$invalid) {
                // maj erreurs
                thisObj.sujetErrors();
                thisObj.structureErrors();
                thisObj.messageErrors();
                thisObj.adresseMailErrors();
                thisObj.prenomErrors(); // dans l'ordre inverse pour le focus
                thisObj.nomErrors(); // dans l'ordre inverse pour le focus
                return false
            }


            // on sauvegarde (distant et local)
            thisObj.old = JSON.parse(JSON.stringify(thisObj.form)) // l'éta "en cours" du formulaire devient l'ancien (pour la restauration)
            var entityToSave = JSON.parse(JSON.stringify(thisObj.form))
            console.log("saveForm OK")

            // dès feu vert de Greg
            return await addeoPost.posterContact(entityToSave) // on sauvegarde notre propriété (en DB locale et SI POSSIBLE en ligne)
            //return await addeoPost.posterContactLORFOLIO(entityToSave) // on sauvegarde notre propriété (en DB locale et SI POSSIBLE en ligne)
             .then(response => {
                var message = "";
                var ok = false;
                //var ok = true;
                if (this.markOrientest) {
                    if (!response || !response.ok) {
                        message = "Votre proposition n'a pu être transmise : veuillez envoyer un mail à contact@orientest.fr";
                    }
                    else {
                        message = "Votre proposition a bien été transmise. L'équipe Orient'Est vous remercie et reviendra vers vous dans les meilleurs délais.";
                        ok = true;
                    }
                }
                else {
                    if (!response || !response.ok) {
                        message = "Votre demande de contact n'a pu être transmise : veuillez envoyer un mail à contact@lorfolio.fr";
                    }
                    else {
                        message = "Votre demande de contact a bien été transmise. L'équipe Lorfolio vous remercie et reviendra vers vous dans les meilleurs délais.";
                        ok = true;
                    }
                }

                // on prépare le message passé à la prochaine page
//                message = "La saisie de la propriété "+this.form.identifiant+ " a bien été enregistrée.";

                // on va au bon endroit avec les bonnes valeurs
                this.terminerEdition(message, ok);
            })

        },


        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message, ok) {
            if (message) {
                this.message=true;
                this.messageTxt=message;
                this.messageOK=ok;
                this.myMessageTimeout = setTimeout(this.finMessage, 5000);
            }

            if (ok) {
                this.reinitForm();
            }
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.nom = ''
            this.error.adresseMail = ''
            this.error.sujet = ''
            this.error.resultat = ''
            this.error.statut = ''
            this.error.autreStatut = ''
            this.error.message = ''
        },
        prenomErrors () {
            const errors = [];
            if (this.$v.form.prenom.$invalid) {
                this.error.prenom = "Vous devez obligatoirement saisir votre prénom";
                if (!this.$v.form.prenom.maxLength) {
                    this.error.prenom  = "La taille maximale pour cette saisie est de 600 caractères"
                }
                this.$refs.prenom.focus();
                return false;
            }
            else {
                this.error.prenom = "";
            }
            return true;
        },
        nomErrors () {
            const errors = [];
            if (this.$v.form.nom.$invalid) {
                this.error.nom = "Vous devez obligatoirement saisir votre nom";
                if (!this.$v.form.nom.maxLength) {
                    this.error.nom  = "La taille maximale pour cette saisie est de 600 caractères"
                }
                this.$refs.nom.focus();
                return false;
            }
            else {
                this.error.nom = "";
            }
            return true;
        },
        structureErrors () {
            const errors = [];
            if (this.$v.form.structure.$invalid) {
                this.error.structure = "Vous devez obligatoirement saisir une structure";
                if (!this.$v.form.structure.maxLength) {
                    this.error.structure  = "La taille maximale de la structure est de 600 caractères"
                }
                this.$refs.structure.focus();
                return false;
            }
            else {
                this.error.structure = "";
            }
            return true;
        },
        messageErrors () {
            const errors = [];
            if (this.$v.form.message.$invalid) {
                this.error.message = "Vous devez obligatoirement saisir une proposition d'événement";
                if (!this.$v.form.message.maxLength) {
                    this.error.message  = "La taille maximale du message est de 1265 caractères"
                }
                this.$refs.message.focus();
                return false;
            }
            else {
                this.error.message = "";
            }
            return true;
        },
        adresseMailErrors () {
            const errors = [];
            if (!this.$v.form.adresseMail.email || this.$v.form.adresseMail.$invalid) {
                this.error.adresseMail = "Vous devez saisir une adresse courriel valide";
                this.$refs.adresseMail.focus();
                return false;
            }
            else {
                this.error.adresseMail = "";
            }
            return true;
        },
        sujetErrors () {
            const errors = [];
            if (this.$v.form.sujet.$invalid) {
                this.error.sujet = "Vous devez indiquer la raison pour laquelle vous nous contactez : une proposition pour l'agenda !";
                if (!this.$v.form.sujet.maxLength) {
                    this.error.sujet  = "La taille maximale pour cette saisie est de 600 caractères"
                }
                this.$refs.sujet.focus();
                return false;
            }
            else {
                this.error.sujet = "";
            }
            return true;
        },
        statutIsFalse () {
            /*
            if (this.form.statut == "") {
                this.error.statut = "Vous devez indiquer votre situation";
                this.$refs.statut.focus();
                return true;
            }
            else {
                if (this.autre && this.form.autreStatut == "") {
                    this.error.autreStatut = "Vous devez préciser quelle est votre situation";
                    this.$refs.autreStatut.focus();
                    return true;
                }
                this.error.statut = "";
                this.error.autreStatut = "";
            }
            */
            return false;
        },
        resultatIsFalse () {
            if (this.resultat != (this.antiSpam1 + this.antiSpam2)) {
                if (this.resultat == "") {
                    this.error.resultat = "Vous devez indiquer le résultat du calcul";
                }
                else {
                    this.error.resultat = "Vous devez indiquer le résultat correct du calcul";
                }
                this.$refs.resultat.focus();
                return true;
            }
            else {
                this.error.resultat = "";
            }
            return false;
        },
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .addeoSmall {
        #contact {
            #container-contact {
                div.edition {
                    width: 95%;
                    max-width: 1000px;
                }
                .boutonsValidation {
                    width: 95%;
                    margin: 0 auto;
                    button, a {
                        width:75%;
                        max-width: 75px;
                    }
                }
            }
        }
    }

    #contact {

        $couleur-fond: #eee;
        $couleur-texte: #223938;

        #container-contact {
            text-align: center;

            h3 {
                color:#006EAB;
                font-size: 1.6em;
            }

            div.consigne {
                margin:1em 0;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }
            .consigneCalcul {
                margin:0.3em 0;
                color:#356A73;
            }

            div.edition {
                width: 100%;
                max-width: 405px;
                margin: 1em auto;
                text-align: left;
            }

            .v-input {

                .v-input__slot, .v-text-field__slot {
                    background-color: #F8F8FC;
                    border-radius: 5px 5px 0 0;

                    input, textarea {
                        margin:0.4em 2em;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    label {
                        left:1em !important;
                        right:auto !important;
                    }
                }
                .v-input__slot:before {
                    border-color: #006EAB;
                }
                .v-messages__message {
                    color:darkorange;
                }

            }
        }
    }



html {
    body {
        #app.addeoSmall, #app.addeoMedium, #app.addeoLarge  {
            #message {

                width: 96%;
                padding:1em 2%;
                min-height: 3em;
                height: auto;

                &.messageErreur {
                    background-color: firebrick !important;
                }
            }
            .scrollup {
                display:none;
            }
            .containerAffichage {
                margin: 0 auto;
                padding: 2% 0;
            }
        }
    }
}



/**/
    @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
    /**/


    /* reprise matérial design : 2022-02 */

    #blocVersion {
        display:none;
    }

    #contactAgenda {

        #container-contact {
            font-family: Roboto, Arial, sans-serif;
            $couleur-fond: #eee;
            $couleur-texte: #223938;

            text-align: center;
            width: 100%;

            #autreStatut {
                margin-top:-0.9em;
            }

            .containerAttention {
                margin:0 auto 3em;
                /*border:3px orange solid;*/
                max-width: 640px;
            }

            div.infoBis {
                margin:1em 0;
                max-width: 600px;
                width: 96%;
                text-align: left;
                font-size: 0.85em;
                display: inline-block;
                font-weight: bold;
                opacity: 0.8;
            }

            div.consigne {
                margin:1em 0 0em;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }
            .consigneAntiSpam {
                padding-bottom: 0.4em;
                display: inline-block;
                width:100%;
                color: #000;
            }
            .consigneAntiSpam .consigne, .consigneContextuelle.consigne {
                color: #2c3e50;
                /*color:#356A73;*/
                display:block;
                padding:0.4em 0;
                font-size: 90%;
            }
            .consigneContextuelle.consigne {
                margin-bottom : -1em;
            }

            div.edition {
                width: 100%;
                max-width: 600px;
                /*margin: 1.0em auto 0.8em;*/
                margin: 10px auto 10px;
                text-align: left;
            }
            .consigneAntiSpam {
                margin-top: 0.6em;
                .v-input {
                    margin: 0;
                    }
            }

            .v-input {
                display:inline-block;
                margin: 0.6em 0 0;
                padding:0;
                /*
                width: 33%;
                max-width: 280px;
                */
                width: 100%;

                .v-input__slot, .v-text-field__slot {

                    background-color:#EBEBEB;
                    color:#252525;
                    background-color:#EBEBEB;
                    color: #2C3E50;

                    border-radius: 4px 4px 0 0;
                    width:100%;
                    margin-bottom:0;
                    /*margin-bottom: 1.4em;*/

                    input, textarea, div.v-select__selections {
                        font-size:100%;
                        margin:0 5%;
                        padding:1.0em 0 0.1em;
                        width:90%;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    textarea {
                        margin-right: 0;
                    }

                    div.v-select__selections {
                        input {
                            padding-top:0;
                        }
                    }

                    label {
                        left:1em !important;
                        top:0.6em !important;
                        right:auto !important;
                        /*color:#356A73;*/
                        color:#006EAB;
                        width: 100%;
                    }
                    .v-label--active {
                        /*transform: translateY(-8px) scale(.6);*/
                        transform: translateY(-10px) scale(.6);
                        left: -15% !important;
                        right:unset !important;
                    }
                }

                &.v-select:not(.v-select--is-menu-active) {
                    .v-input__append-inner {
                        margin: 6px 4px 0 0;
                        background: no-repeat center center transparent url("~@/assets/img/oe/down.png");
                        background-size: 60%;
                    }
                }
                .v-list-item__content {
                    color: #006EAB;
                }


                .v-text-field__details {
                    height: 9px;
                    left: 0;
                    margin:0.1em 0 0.2em;
                    /*
                    position: absolute;
                    top: -1.4em;
                    */
                    .v-messages {
                        min-height: 0px;
                        display:inline;
                        .v-messages__message {
                            /*color:#b00020;*/
                            color:#be3e11;
                            font-size: 85%;
                            vertical-align: top;
                        }
                    }
                    .v-counter {
                        margin-top:0.2em;
                    }
                }
                .v-select-list {
                    .v-list-item--link:hover::before {
                        background-color: #00a0ff;
                        /*opacity: 1;*/
                    }
                }

                &.error--text {
                    label {
                        /*color: #b00020;*/
                        color:#be3e11;
                    }
                    .v-input__slot:before {
                        /*border-color: #b00020;*/
                        color:#be3e11;
                    }
                }
            }
            .v-input:not(.v-input--is-label-active) {
                .v-input__slot {
                    .v-text-field__slot {
                        input {
                            /*
                            color:transparent;
                            */
                        }
                    }
                }
            }
            .boutonsValidation {
                font-family: Montserrat, Roboto, Arial, sans-serif;
                text-align: right;
                button, a {
                    font-size: 70%;
                    letter-spacing: 0.03em;
                    padding: 2px 9px;
                    margin-top:0;
                    height:30px;
                    text-transform: none;

                    color:white;
                    background-color: #005684;
                    border-radius: 0;
                    box-shadow: none;
                    border: 1px solid #005684;
                    .v-btn__content {
                        background-color: transparent;
                    }
                    &:hover {
                        background-color: #fff !important;
                        color: #356a73;
                        &:before  {
                            opacity: 1;
                            background-color: transparent;
                            transition: none;
                        }
                    }
                    &.centrer {
                        margin:0 auto;
                    }
                }
                &.centrer {
                    text-align: center;
                }
            }
            .containerRedacteur {
                margin:1em 0 2em;
                .inscriptionRedacteur {
                    font-size: 1.0em;
                    a {
                        text-decoration: underline;
                        &:hover, &:focus {
                            text-decoration: none;
                        }
                    }
                }
                .boutonInscription {
                    margin:1em 0;
                    a {
                        font-size: 1em;
                        padding:0.4em 1em;
                        background-color: #356974;
                        border-color: #356974;
                    }
                }
            }

        }


    }


</style>
