
import axios from 'axios'
//const DELAI_CACHE = 2 * 60 * 1000; // le premier chiffre donne les minutes
const DELAI_CACHE = 0.1 * 60 * 1000; // le premier chiffre donne les minutes


export const addeoGet = {

        navigateurEnLigne() {
            return(navigateurEnLigne());
        },

        async getRencontres(leStore) {
            if (navigator.onLine) {
                var paramApi = ajaxDomaine()+"/agenda/ajax_api_portail_rencontres";
                return(getDataApi(paramApi, leStore));
            }
            else {
                return(null)
            }
        },

        async nbSessionsFormationReferencee(ref, refBloc, leStore) {
            if (navigator.onLine) {
                var paramApi = ajaxDomaine()+"/ppfproduits/getListForPortail";
                let monRetour = new Object();
                monRetour.nbSessions = 0;
                monRetour.url = "";
                return(getDataApi(paramApi, leStore))
                    .then(response => {
                        if (response && response.data && response.data.ok) {
                            let datas = response.data.data;
                            let blocsFormations = datas.blocsFormations;
                            for (let i = 0; i < blocsFormations.length; i++) {
                                let blocFormation = blocsFormations[i];
                                if (blocFormation.code_type == refBloc) {
                                    let lesFormations = blocFormation.formations;
                                    for (let iB = 0; iB < lesFormations.length; iB++) {
                                        let laFormation = lesFormations[iB];
                                        if (laFormation.reference == ref) {
                                            for (let iF = 0; iF < laFormation.Ppfsessions.length; iF++) {
                                                let laSession = laFormation.Ppfsessions[iF];
                                                if (laSession.inscription_possible) {
                                                    monRetour.nbSessions++;
                                                    monRetour.url = laSession.inscription_url;
                                                }
                                            }
                                        return(monRetour); // inutile de continuer - c'est bon
                                        }
                                    }
                                }
                            }
                            return(monRetour); // vide
                        }
                        else {
                            return(monRetour); // vide
                        }
                    })
            }
            else {
                return(monRetour);
            }
        },
        async getProfessionnalisation(leStore) {
            if (navigator.onLine) {
                var paramApi = ajaxDomaine()+"/ppfproduits/getListForPortail";
                return(getDataApi(paramApi, leStore));
            }
            else {
                return(null)
            }
        },

    }
export const addeoPost = {

        navigateurEnLigne() {
            return(navigateurEnLigne());
        },

        //async posterContact(contact) {
        async posterContactProxy(contact) {
            if (navigator.onLine) {
                var leProxy = quelProxy();
                var paramApi = ajaxDomaine2()+"/"+leProxy+".php?route=/remarques/add_from_portail";

                return(postDataApi(paramApi, contact));
            }
            else {
                return(null)
            }
        },

        async posterContactLORFOLIO(contact) {
            if (navigator.onLine) {
                var paramApi = ajaxDomaine2()+"/proxyLorfolio.php?route=/remarques/add_from_portail";

                return(postDataApi(paramApi, contact));
            }
            else {
                return(null)
            }
        },


        //async posterContactDirect(contact) {
        async posterContact(contact) {
            if (navigator.onLine) {
                appAttendre(true); // on attend

                var paramApi = ajaxDomaine()+"/remarques/add_from_portail";

                return(postDataApi(paramApi, contact));
            }
            else {
                return(null)
            }
        },
    }


async function getDataApi(paramApi, leStore, utiliserCache = 1) {
        var leMaintenant = Date.now();
        var localCacheAddeo = leStore.getters.cacheAddeo;
        if (utiliserCache && localCacheAddeo && (typeof localCacheAddeo.expires !== "undefined") && (leMaintenant < localCacheAddeo.expires)) {
            return(localCacheAddeo);
        }

    if (navigator.onLine) {
        appAttendre(true); // on attend

        return axios.get(paramApi)
            .then(response => {
                appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined")) {
                    // cache totalement manuel
                    localCacheAddeo = new Object();
                    localCacheAddeo.expires = leMaintenant+DELAI_CACHE;
                    localCacheAddeo.data = response.data;
                    leStore.commit('setCacheAddeo', localCacheAddeo); // on commence par stocker
                    return(response);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                return(null);
                });

        /*
// avec cache de axios-cache-adapter : j'ai pas compris l'intérêt !!!
        return apiCacheAddeo({
            url: paramApi,
            method: 'get'
            }).then(async (response) => {

            var retour = JSON.parse(cacheAddeo.store.store[paramApi]);
            leStore.commit('setCacheAddeo', retour); // on commence par stocker
            var retourData = retour.data;

            return(retourData);
            })

        */
        }
    else {
        appAttendre(false); // on n'attend plus
        return(null);
    }
}

async function postDataApi(paramApi, paramPost) {
    if (navigator.onLine) {
        appAttendre(true); // on attend

        // pour le croos domain
        /* tmp 11/2024
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        */
        // et le post
        return axios.post(paramApi, {
            param:paramPost
            })
            .then(response => {
                appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined")) {
                    return(response.data);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                appAttendre(false); // on n'attend plus
                return(null);
                });
        }
    else {
        appAttendre(false); // on n'attend plus
        return(null);
    }
}

async function postDataApi2(paramApi, paramPost) {
    var apikey = "aGxuvXkn3m6t";
    if (navigator.onLine) {
        appAttendre(true); // on attend

        // pour le cross domain
        //axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        /*
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['apikey'] = apikey;
        */
        const config = {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                'apikey': apikey
            }
        }

        /*const params = new URLSearchParams();
        params.append('data', paramPost);
        */
        var params = paramPost;

        // et le post
/*        return axios.post(paramApi, {
            data:paramPost
            })
*/
        return axios.post(paramApi, params, config)
            .then(response => {
                appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined")) {
                    return(response.data);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                appAttendre(false); // on n'attend plus
                return(null);
                });
        }
    else {
        appAttendre(false); // on n'attend plus
        return(null);
    }
}


function toggleClassElt(elt, addRemoveClass, className) {
    if (elt) {
        if (addRemoveClass === 'addClass') {
            elt.classList.add(className);
        }
        else {
            elt.classList.remove(className);
        }
    }
}

function appAttendre(attendre) {
        var addRemoveClass ='addClass';
        if (!attendre) {
            addRemoveClass ='removeClass';
        }
        var eltApp = document.getElementById("app");
        toggleClassElt(eltApp, addRemoveClass, "curseurAttendre");
    }

function quelProxy() {
    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        console.log('quelProxy A');
        return("proxest");
    }

    if (leDomaine.indexOf("grandtest") >= 0) {
        console.log('quelProxy B');
        return("proxest");
    }
    console.log('quelProxy C');
    return("proxy");
}


function ajaxDomaine2() {

    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return("http://portailfolio.grandtest.addeo.ovh/");
    }

    if (leDomaine.indexOf("grandtest") >= 0) {
        return("http://"+leDomaine);
    }
    return("https://"+leDomaine);
}

function ajaxDomaine() {

    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return("https://grandtest.addeo.ovh");
    }

    if (leDomaine.indexOf("grandtest") >= 0) {
        return("https://grandtest.addeo.ovh");
    }

    if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr")  && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") &&
        (leDomaine !== "monorientest.fr") && (leDomaine !== "www.monorientest.fr") && (leDomaine !== "mon.orientest.fr") && (leDomaine !== "www.mon.orientest.fr") && (leDomaine !== "orientest.pro") && (leDomaine !== "www.orientest.pro")  && (leDomaine !== "ressources.orientest.pro") && (leDomaine.indexOf("localhost")< 0) ) {
        return("https://preprod.mon.orientest.fr");
    }
    else {
         if ((leDomaine === "mon.orientest.fr") || (leDomaine === "www.mon.orientest.fr") || (leDomaine === "monorientest.fr") || (leDomaine === "www.monorientest.fr") || (leDomaine === "orientest.pro") || (leDomaine === "www.orientest.pro")  || (leDomaine === "ressources.orientest.pro")) {
            return('https://mon.orientest.fr') // la bonne URL
         }
         if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
            return('https://mon.lorfolio.fr') // la bonne URL
         }
    }
}

function lURLDomaine() {
    var leDomaine = window.location.hostname;
    // quel domaine
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return("https://grandtest.addeo.ovh"); // URL de test
    }
    else {
        if (leDomaine.indexOf("grandtest") >= 0) {
            return("https://grandtest.addeo.ovh"); // URL de test
        }
        else {
            if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                return("https://preprod.lorfolio.fr"); // URL de preprod
            }
            else {
                 if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                    return('https://mon.lorfolio.fr'); // URL de prod
                 }
            }
        }
    }
}

function skipProxy() {
    var urlPOST = lURLDomaine();
    /*
    // pro ou pas pro ?
    if (this.$parent.sitePRO) {
        urlPOST += this.lesPros;
    }
    else {
        urlPOST += this.lesAutres;
    }
    */
    return(urlPOST);
    }

